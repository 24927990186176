import React from 'react'
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { FiInstagram } from 'react-icons/fi'
import './footer.css'


const Footer = () => {
  return (
    <footer>
      <a href='#home' className='footer__logo'>Ankit Basare</a>
      <ul className='permalinks'>
        <li><a href='#home'>Home</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#project'>Projects</a></li>
        <li><a href='#education'>Education</a></li>
        <li><a href='#skills'>Skills</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className='footer__socials'>
        <a href="https://www.linkedin.com/in/ankit-basare/" target="_blank" rel="noopener noreferrer">
          <BsLinkedin />
        </a>
        <a href="https://github.com/Kit105" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </a>
        <a href="https://twitter.com/ankitbasare10" target="_blank" rel="noopener noreferrer">
          <BsTwitter />
        </a>
        <a href="https://www.instagram.com/funk_it10/" target="_blank" rel="noopener noreferrer">
          <FiInstagram />
        </a>


      </div>

    </footer>
  )
}

export default Footer