import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from '../../assets/me-about.jpg'
import HeaderSocials from "./HeaderSocials";
import {AiOutlineArrowRight} from "react-icons/ai";

const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h5>Hey there! I'm</h5>
        <h1>Ankit Basare</h1>
        <h4 className="text-light">Software Developer</h4>
        <h5 className="text-light header__text">
          I like to build robust and scalable web applications with a great user experience. <br />
          Currently based in London, UK.
        </h5>
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        <CTA />
        <HeaderSocials />

        <a href="#contact" className="scroll__down">
          <AiOutlineArrowRight />
        </a>
      </div>
    </header>
  );
};

export default Header;
