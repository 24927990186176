import React from "react";
import "./experience.css";
import NewfoldLogo from "../../assets/newfold-logo.svg";
import EIGLogo from "../../assets/eig-logo.svg";
import ThinkologyLogo from "../../assets/thinology.svg";

const Experience = () => {
  return (
    <section id="experience">
      <h2>Work Experience</h2>

      <div className="container experience__container">
        <div className="experience__cards">
          <article className="experience__card">
            <div className="experience__img__container">
              <img src={ThinkologyLogo} alt="thinkology-logo" />
            </div>
            <div className="experience__data__container">
              <div className="experience__data">
                <div className="experience__titles">
                  <h3>Data Science Intern</h3>
                  <h3 className="text-light">Thinkology | London,UK</h3>
                </div>
                <div className="experience__duration">
                  <h3>March 2024 - Present</h3>
                </div>
              </div>

              <div className="experience__description">
                Leading advanced human language sentiment analysis research for
                EveryView survey software, resulting in the development of
                innovative machine learning models to analyse user sentiment and
                engagement effectively.
              </div>

            </div>
          </article>
          <article className="experience__card">
            <div className="experience__img__container">
              <img src={NewfoldLogo} alt="newfold-logo" />
            </div>
            <div className="experience__data__container">
              <div className="experience__data">
                <div className="experience__titles">
                  <h3>Application Developer</h3>
                  <h3 className="text-light">
                    Newfold Digital | Mumbai, India
                  </h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className="experience__duration">
                  <h3>April 2020 - August 2022</h3>
                </div>
              </div>

              <div className="experience__description">
                Developed and managed a diverse portfolio of over 15
                applications and web tools catering to both internal operations
                and customer-facing needs.
              </div>
            </div>
          </article>
          <article className="experience__card">
            <div className="experience__img__container">
              <img src={EIGLogo} alt="newfold-logo" />
            </div>
            <div className="experience__data__container">
              <div className="experience__data">
                <div className="experience__titles">
                  <h3>Web Solution Specialist</h3>
                  <h3 className="text-light">
                    Endurance International Group | Mumbai, India
                  </h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className="experience__duration">
                  <h3>January 2019 - March 2020</h3>
                </div>
              </div>

              <div className="experience__description">
                Led the development of robust domains and server management
                systems which enabled effective administration and control of
                domains and servers, facilitating smooth operations and
                enhancing overall infrastructure management.
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Experience;
