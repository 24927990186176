import React from 'react'
import './education.css'
import UoELogo from '../../assets/uoe-logo.svg'
import UniversalLogo from '../../assets/universal-logo.svg'

const Education = () => {
  return (
    <section id='education'>
      <h2>Education</h2>

      <div className='container education__container'>
        <div className="education__cards">
          <article className="education__card">
            <div className='education__img__container'>
              <img src={UoELogo} alt="uoe-logo" />
            </div>
            <div className='education__data__container'>
              <div className='education__data'>
                <div className='education__titles'>
                  <h3>MSc. Advanced Computer Science</h3>
                  <h3 className='text-light'>University of Exeter | Exeter, UK</h3>
                </div>
                <div className='education__duration'>
                  <h3>Sept 2022 - Sept 2023</h3>
                </div>
              </div>

              {/* <div className='education__description'>
                Developed and managed a diverse portfolio of over 15 applications and web tools catering to both internal operations and customer-facing needs.
              </div> */}

            </div>
          </article>
          <article className="education__card">
            <div className='education__img__container'>
              <img src={UniversalLogo} alt="universal-logo" />
            </div>
            <div className='education__data__container'>
              <div className='education__data'>
                <div className='education__titles'>
                  <h3>Bachelor of Engineering - </h3>
                  <h3>Electronics and Telecommunications</h3>
                  <h3 className='text-light'>Universal College of Engineering, University of Mumbai</h3>
                  <h3 className='text-light'>Mumbai, India</h3>
                </div>
                <div className='education__duration'>
                  <h3>June 2014 - May 2018</h3>
                </div>
              </div>

              {/* <div className='education__description'>
                Developed and managed a diverse portfolio of over 15 applications and web tools catering to both internal operations and customer-facing needs.
              </div> */}

            </div>
          </article>
        </div>
      </div>

    </section>
  )
}

export default Education