import React from 'react'
import './skills.css'
import {SiJavascript, SiMongodb, SiMysql, SiPhp, SiTypescript, SiTailwindcss} from 'react-icons/si'
import {BsBootstrapFill} from 'react-icons/bs'
import {FaPython, FaLaravel, FaNodeJs, FaReact, FaCss3Alt} from 'react-icons/fa'

const Skills = () => {
  return (
    <section id='skills'>
      <h2>Technical Skills</h2>

      <div className='container skills__container'>
        <div className='skills__frontend'>
          <h3>Frontend Development</h3>
          <div className="skills__content">
            <article className='__details'>
              <FaReact className='__details-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'>Experienced</small>
              </div>              
            </article>
            <article className='__details'>
              <SiJavascript className='__details-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='__details'>
              <SiTailwindcss className='__details-icon' />
              <div>
                <h4>Tailwind</h4>
                <small className='text-light'>Intermediate</small>
              </div>              
            </article>

            <article className='__details'>
              <BsBootstrapFill className='__details-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>Experienced</small>
              </div>              
            </article>
            <article className='__details'>
              <FaCss3Alt className='__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>              
            </article>
            <article className='__details'>
              <SiTypescript className='__details-icon' />
              <div>
                <h4>TypeScript</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div> 
        </div>

        <div className='skills__backend'>
          <h3>Backend Development</h3>
          <div className="skills__content">
            <article className='__details'>
              <FaNodeJs className='__details-icon' />
              <div>
                <h4>NodeJS</h4>
                <small className='text-light'>Experienced</small>
              </div>              
            </article>
            <article className='__details'>
              <SiMongodb className='__details-icon' />
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='__details'>
              <FaLaravel className='__details-icon' />
              <div>
                <h4>Laravel</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='__details'>
              <SiMysql className='__details-icon' />
              <div>
                <h4>SQL</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>
            <article className='__details'>
              <FaPython className='__details-icon' />
              <div>
                <h4>Python</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='__details'>
              <SiPhp className='__details-icon' />
              <div>
                <h4>PHP</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div> 
        </div>
      </div>
    
    </section>
  )
}

export default Skills