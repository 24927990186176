import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { SiMaildotru } from "react-icons/si";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/ankit-basare/" target="_blank" rel="noopener noreferrer">
        <BsLinkedin />
      </a>
      <a href="https://github.com/Kit105" target="_blank" rel="noopener noreferrer">
        <FaGithub />
      </a>
      <a href="https://twitter.com/ankitbasare10" target="_blank" rel="noopener noreferrer">
        <BsTwitter />
      </a>
      <a href="mailto:ankitbasare@gmail.com" target="_blank" rel="noopener noreferrer">
        <SiMaildotru />
      </a>

    </div>
  );
};

export default HeaderSocials;
