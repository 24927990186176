import React, { useState, useEffect } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { FaProjectDiagram, FaUniversity, FaTools } from "react-icons/fa";
import { BiBook, BiMessageSquareDetail } from 'react-icons/bi';
import './nav.css';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  useEffect(() => {
    const handleScroll = () => {
      // Determine which section is currently in view based on the scroll position
      const homeSection = document.getElementById('home');
      const experienceSection = document.getElementById('experience');
      const projectSection = document.getElementById('project');
      const educationSection = document.getElementById('education');
      const skillsSection = document.getElementById('skills');
      const contactSection = document.getElementById('contact');

      const windowHeight = window.innerHeight;

      const isSectionInCenter = (section) => {
        const rect = section.getBoundingClientRect();
        return rect.top < windowHeight / 2 && rect.bottom > windowHeight / 2;
      };

      if (isSectionInCenter(homeSection)) {
        setActiveNav('#home');
      } else if (isSectionInCenter(experienceSection)) {
        setActiveNav('#experience');
      } else if (isSectionInCenter(projectSection)) {
        setActiveNav('#project');
      } else if (isSectionInCenter(educationSection)) {
        setActiveNav('#education');
      } else if (isSectionInCenter(skillsSection)) {
        setActiveNav('#skills');
      } else if (isSectionInCenter(contactSection)) {
        setActiveNav('#contact');
      }
    };

    window.addEventListener('scroll', handleScroll); // Add scroll event listener

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup the event listener
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <nav>
      <a href="#home" className={activeNav === '#home' ? 'active' : ''} onClick={() => setActiveNav('#home')}>
        <AiOutlineHome />
      </a>
      <a href="#experience" className={activeNav === '#experience' ? 'active' : ''} onClick={() => setActiveNav('#experience')}>
        <BiBook />
      </a>
      <a href="#project" className={activeNav === '#project' ? 'active' : ''} onClick={() => setActiveNav('#project')}>
        <FaProjectDiagram />
      </a>
      <a href="#education" className={activeNav === '#education' ? 'active' : ''} onClick={() => setActiveNav('#education')}>
        <FaUniversity />
      </a>
      <a href="#skills" className={activeNav === '#skills' ? 'active' : ''} onClick={() => setActiveNav('#skills')}>
        <FaTools />
      </a>
      <a href="#contact" className={activeNav === '#contact' ? 'active' : ''} onClick={() => setActiveNav('#contact')}>
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Nav;
