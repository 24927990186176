import React from "react";
import "./projects.css";
import { FaLaravel } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaReact, FaNodeJs, FaDocker } from "react-icons/fa";
import { SiExpress, SiRedux } from "react-icons/si";
import { SiMongodb, SiTailwindcss } from "react-icons/si";
import { DiMysql } from "react-icons/di";
import { SiReactrouter } from "react-icons/si";
import { SiAmazonaws } from "react-icons/si";
import { GiTakeMyMoney } from "react-icons/gi";
import { AiOutlineFileSearch } from "react-icons/ai";

const Projects = () => {
  return (
    <section id="project">
      <h2>Projects</h2>

      <div className="container project__container">
        <div className="project__cards">
          <article className="project__card">
            <div className="project__img__container">
              <AiOutlineFileSearch size={90} color={"#61DAFB"} />
            </div>
            <div className="project__data__container">
              <div className="project__data">
                <div className="project__titles">
                  <h3>TechFix Pro Dashboard</h3>
                  <h3 className="text-light">
                    User Management & Notes System - Project
                  </h3>
                </div>
                <div className="project__duration">
                  <h3>Jan 2024 - March 2024</h3>
                </div>
              </div>

              <div className="project__description">
                Leading advanced human language sentiment analysis research for
                EveryView survey software, resulting in the development of
                innovative machine learning models to analyse user sentiment and
                engagement effectively.
              </div>

              <div className="skillcards">
                <div className="skillcard tooltip-container">
                  <FaReact color="#61DAFB" size="1.5rem" />
                  <span className="tooltip">React</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiRedux color="#764ABC" size="1.5rem" />
                  <span className="tooltip">Redux</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiReactrouter color="#CA4245" size="1.5rem" />
                  <span className="tooltip">React Router</span>
                </div>
                <div className="skillcard tooltip-container">
                  <FaNodeJs color="#8CC84B" size="1.5rem" />
                  <span className="tooltip">Node.js</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiMongodb color="#13aa52" size="1.5rem" />
                  <span className="tooltip">MongoDB</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiExpress color="whitesmoke" size="1.5rem" />
                  <span className="tooltip">Express</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiTailwindcss color="#06B6D4" size="1.5rem" />
                  <span className="tooltip">Tailwind</span>
                </div>
              </div>
            </div>
          </article>
          <article className="project__card">
            <div className="project__img__container">
              <IoLogoJavascript size={90} color={"#f0db4f"} />
            </div>
            <div className="project__data__container">
              <div className="project__data">
                <div className="project__titles">
                  <h3>Hopp App</h3>
                  <h3 className="text-light">
                    1st place - JunctionX Hackathon
                  </h3>
                </div>
                <div className="project__duration">
                  <h3>Sept 2022 - Sept 2023</h3>
                </div>
              </div>

              <div className="project__description">
                Developed a MERN stack web app enhancing UK healthcare by
                providing a comprehensive platform for seamless exploration of
                activities, meetups, and immediate support, fostering community
                engagement and well-being.
              </div>

              <div className="skillcards">
                <div className="skillcard tooltip-container">
                  <FaReact color="#61DAFB" size="2rem" />
                  <span className="tooltip">React</span>
                </div>
                <div className="skillcard tooltip-container">
                  <FaNodeJs color="#8CC84B" size="2rem" />
                  <span className="tooltip">Node.js</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiMongodb color="#13aa52" size="2rem" />
                  <span className="tooltip">MongoDB</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiExpress color="whitesmoke" size="2rem" />
                  <span className="tooltip">Express</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiTailwindcss color="#06B6D4" size="2rem" />
                  <span className="tooltip">Tailwind</span>
                </div>
              </div>
            </div>
          </article>

          <article className="project__card">
            <div className="project__img__container">
              <GiTakeMyMoney size={90} color={"#00BFFF"} />
            </div>
            <div className="project__data__container">
              <div className="project__data">
                <div className="project__titles">
                  <h3>Billing Portal</h3>
                  <h3 className="text-light">
                    CallMediLife Healthcare Services Pvt. Ltd.{" "}
                  </h3>
                </div>

                <div className="project__duration">
                  <h3>Nov 2022 - Jan 2023</h3>
                </div>
              </div>

              <div className="project__description">
                Implemented a Laravel app to streamline medical health records,
                cost calculations, and pricing for 10+ medical centers.
                Empowered administrators with efficient pricing management,
                report generation, and streamlined administrative tasks,
                resulting in significant time savings of over 10 hours per week.
              </div>
              <div className="skillcards">
                <div className="skillcard tooltip-container">
                  <FaLaravel color="#f05340" size="2rem" />
                  <span className="tooltip">Laravel</span>
                </div>
                <div className="skillcard tooltip-container">
                  <FaReact color="#61DAFB" size="2rem" />
                  <span className="tooltip">React</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiTailwindcss color="#06B6D4" size="2rem" />
                  <span className="tooltip">Tailwind</span>
                </div>
                <div className="skillcard tooltip-container">
                  <DiMysql color="#00758F" size="2rem" />
                  <span className="tooltip">MySQL</span>
                </div>
                <div className="skillcard tooltip-container">
                  <FaDocker color="#2496ED" size="2rem" />
                  <span className="tooltip">Docker</span>
                </div>
                <div className="skillcard tooltip-container">
                  <SiAmazonaws color="#FF9900" size="2rem" />
                  <span className="tooltip">AWS</span>
                </div>
              </div>
              <div className="skillcards"></div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Projects;
